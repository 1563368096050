<template>
  <div>
    <h1 class="mb-4">Calendar</h1>
    <v-sheet tile outlined class="d-flex py-2">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-22"
        label="type"
      ></v-select>
      <!-- <v-select
        v-model="mode"
        :items="modes"
        dense
        outlined
        hide-details
        label="event-overlap-mode"
        class="ma-2"
      ></v-select> -->
      <!-- <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="weekdays"
        class="ma-2"
      ></v-select> -->
      <v-spacer></v-spacer>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet>
      <v-calendar ref="calendar" :type="type" v-model="value"></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "month",
      types: ["month", "week", "day", "4day"],
      value: "",
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-calendar-weekly__day {
  padding: 2rem 1rem;
}
</style>
